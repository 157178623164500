
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import ProjectMap from '@/pages/project/components/project-map.vue';
import { InvalidMessages } from '@/mixins/validation-mixin';

export interface DemoGraphic {
  gender: string[],
  maxAge: number,
  minAge: number,
  region: string[],
}

@Component({
  components: {
    ProjectMap,
  },
})
export default class ProjectDemoGraphics extends Vue {
  @Prop({ default: true }) editable!: boolean;
  @Prop() invalidMessages!: Array<InvalidMessages>;
  @Prop() invalidMessage!: (field: string) => string | null;
  @Prop() validation!: (field: string) => boolean | null;
  @Prop() country!: string[];
  @PropSync('gender') genderSync!: string[];
  @PropSync('region') regionSync!: string[];
  @PropSync('minAge') minAgeSync!: number;
  @PropSync('maxAge') maxAgeSync!: number;

  genderList: { text: string, value: string }[] = [];
  regionList: { text: string, value: string }[] = [];

  async created() {
    this.loading = true;
    await this.getValues();
    this.loading = false;
  }

  async getValues() {
    try {
      const { result, data, message } = await this.axios.get(`/project/enums/${ this.surveyId }`);
      if (!result) throw message;
      const { gender, region } = data;
      this.genderList = gender;
      this.regionList = region;
    } catch (e) {
      this.validationMessages(e);
    }
  }

  selectedRegion(value: string[]) {
    if (this.regionSync) this.regionSync = value;
  }

  changeRegion(value: string[]) {
    const ref = this.$refs.map;
    if (ref) {
      const child = ref as ProjectMap;
      child.parentSelect(value);
    }
  }

  regionCheckAll() {
    const syncLen = this.regionSync.length;
    this.regionSync.splice(0, this.regionList.length);
    if (this.regionList.length !== syncLen) {
      this.regionList.forEach(({ value }) => this.regionSync.push(value))
    }
  }
}
