
import { Vue, Component, PropSync } from "vue-property-decorator";
import { USER_LEVEL } from "@/enum/enums";

export interface Device {
  cam: boolean,
  desktop: boolean,
  mobile: boolean,
  tablet: boolean,
}

@Component
export default class ProjectSurveyDevice extends Vue {
  @PropSync('cam') camSync!: boolean;
  @PropSync('desktop') desktopSync!: boolean;
  @PropSync('mobile') mobileSync!: boolean;
  @PropSync('tablet') tabletSync!: boolean;
}
