
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { InvalidMessages } from '@/mixins/validation-mixin';
import ContactUs from '@/pages/project/components/contact-us.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

@Component({
  components: { ContactUs },
})
export default class ProjectSurveySpecificData extends RegistrationMixin {
  @Prop({ default: true }) editable!: boolean;
  @Prop() invalidMessages!: Array<InvalidMessages>;
  @Prop() invalidMessage!: (field: string) => string | null;
  @Prop() validation!: (field: string) => boolean | null;
  @PropSync('estimateIncidenceRate') estimateIncidenceRateSync!: number;
  @PropSync('estimateLengthOfInterview') estimateLengthOfInterviewSync!: number;
  @PropSync('completeCount') completeCountSync!: number;
  @PropSync('cpi') cpiSync!: number;
  @PropSync('currency') currencySync!: string;

  private cpiTotal = 0;
  private feasible = 0;
  private digit = '';
  private contactUsFlag = false;
  private managementFee = 0;
  private quotaFullFee = 0;
  private terminateFee = 0;
  private pending = false;

  async created() {
    this.loading = true;
    this.regBtnDisabled = true;
    await this.calcCPI();
    this.loading = false;
    this.regBtnDisabled = false;

  }

  async getFeasibleComplete(params: { wanted: number, IR: number, LOI: number }) {
    const { result, data, message } = await this.axios.get(`/project/feasible-completes/${ this.surveyId }`, {
      params,
    });
    if (!result) throw message;

    const { feasible } = data;
    return feasible;
  }

  async getCPI(params: { wanted: number, IR: number, LOI: number }) {
    const { result, data, message } = await this.axios.get<{ cpi: number; currency: string; total: number; terminate: number; quotaFull: number; managementFee: number; digit: string; }>(`/project/calculate-cpi/${ this.surveyId }`, {
      params,
    });

    if (!result) throw message;
    const { cpi, currency, total, managementFee, terminate, quotaFull, digit } = data;
    return { cpi, currency, total, managementFee, terminate, quotaFull, digit };
  }

  async calcCPI() {
    try {
      if (!this.estimateChk) return;
      this.regBtnDisabled = true;
      const params = {
        wanted: this.completeCountSync,
        IR: this.estimateIncidenceRateSync,
        LOI: this.estimateLengthOfInterviewSync,
      };

      const feasible = await this.getFeasibleComplete(params);
      const { cpi, currency, terminate, total, quotaFull, managementFee, digit } = await this.getCPI(params);

      this.feasible = feasible;
      this.cpiSync = cpi;
      this.currencySync = currency;
      this.cpiTotal = total;
      this.managementFee = managementFee;
      this.quotaFullFee = quotaFull;
      this.terminateFee = terminate;
      this.digit = digit;

      await this.$nextTick();

      this.$emit('calc-cpi', {
        feasible: this.feasible,
        completeCount: this.completeCountSync,
        cpi: this.cpiSync,
        currency: this.currencySync,
        total: this.cpiTotal,
        managementFee: this.managementFee,
        quotaFullFee: this.quotaFullFee,
        terminateFee: this.terminateFee,
      });

    } catch (e) {
      this.validationMessages(e);
    } finally {
      this.regBtnDisabled = false;
    }
  }

  get estimateChk() {
    return true;
    // return this.estimateIncidenceRateSync
    //     && this.estimateIncidenceRateSync > 9
    //     && this.estimateLengthOfInterviewSync
    //     && this.estimateLengthOfInterviewSync < 31;
  }

  @Watch('estimateChk')
  async setBtnDisabled() {
    this.regBtnDisabled = !this.estimateChk;
  }

}
