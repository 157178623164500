
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
Settings.defaultLocale = 'en-us';
import moment from "moment";
import { USER_LEVEL } from "@/enum/enums";
import ContactUs from '@/pages/project/components/contact-us.vue';

@Component({
  components: {
    ContactUs,
    Datetime,
  }
})
export default class ProjectDatetime extends Vue {
  @Prop({ default: 1}) version!: number;
  @Prop() supLoading!: boolean;
  @PropSync('startDate') startDateSync!: string;
  @PropSync('endDate') endDateSync!: string;
  contactUsFlag = false;

  get icon() {
    return this.version === 1 ? 'calendar' : 'edit'
  }

  get dateRange() {
    return {
      end: {
        min: this.isExternalUser ? moment(this.startDateSync).add(48,'h').toISOString() : this.startDateSync,
        max: this.isExternalUser ? moment(this.startDateSync).add(60,'d').toISOString() : '',
      },
      start: {
        min: this.isExternalUser ? moment(this.endDateSync).add(-60,'d').toISOString() : '',
        max: this.isExternalUser ? moment(this.endDateSync).add(-48,'h').toISOString() : this.endDateSync,
      }
    }
  }
}
