import { Vue, Component } from 'vue-property-decorator'

@Component
export class RegistrationMixin extends Vue {
  async next() {
    const result = await this.submit();
    if (result) {
      this.regStep = this.regStep + 1;
      this.toTop();
    }
  }

  async prev() {
    await this.submit();
    this.regStep = this.regStep - 1;
    this.toTop();
  }

  async submit() {
    return false;
  }

  toTop() {
    const { toTop } = this.$refs as { toTop: HTMLElement };
    toTop.focus();
  }

  get regStep(): number {
    return this.$store.getters['registration/step'];
  }

  set regStep(step: number) {
    this.$store.commit('registration/setStep', step);
  }

  get regBtnDisabled(): boolean {
    return this.$store.getters["registration/btnDisabled"];
  }

  set regBtnDisabled(bool: boolean) {
    this.$store.commit('registration/setBtnDisabled', bool);
  }

  get regSurveyId(): string {
    return this.$store.getters["registration/surveyId"];
  }

  set regSurveyId(surveyId: string) {
    this.$store.commit('registration/setSurveyId', surveyId);
  }
}
