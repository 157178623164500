
import { Component, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import { ValidationMixin } from '@/mixins/validation-mixin';
import { RegistrationMixin } from '@/mixins/registration-mixin';

@Component
export default class ProjectSurveyManagers extends mixins(ValidationMixin, RegistrationMixin) {
  @PropSync('manager') managerSync!: string[];
  searchList = [];
  searchKeyword = '';

  async searchManager() {
    try {
      const { result, data, message } = await this.axios.get('/user/search-manager', {
        params: {
          manager: this.searchKeyword,
        },
      });

      if (!result) throw message;

      const { managers } = data;
      this.searchList = managers.filter((r: { userId: string }) => r.userId !== this.getUserId);
    } catch (e) {
      this.validationMessages(e);
    }
  }

  private selectManager (managers: string[]) {
    console.log(managers);
    this.$emit('update:manager', managers);
  }


}
