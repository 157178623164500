
import { Component, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import { ValidationMixin } from '@/mixins/validation-mixin';
import { RegistrationMixin } from '@/mixins/registration-mixin';

export interface Browser {
  [key: string]: boolean;
}

@Component
export default class ProjectSurveyBrowser extends mixins(ValidationMixin, RegistrationMixin) {
  @PropSync('browser') browserSync!: boolean;

}
