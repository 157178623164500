
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';

@Component({
  components: {
    VueEditor,
  },
})
export default class ContactUs extends Vue {
  @PropSync('isShow') isShowSync!: boolean;
  private title = '';
  private content = '';
  private files: File[] = [];

  private selectFiles(files: File[]) {
    console.log(this.files);
    console.log(files)
  }

  private formatNames (files: File[]) {
    return files.length === 1 ? files[0].name : `${files.length} files selected`
  }

  private async submit() {
    try {
      const formData = new FormData();
      for (const file of this.files) {
        formData.append('attachFiles', file);
      }
      // formData.append('title', this.title);
      formData.append('content', this.content);
      console.log(formData);
      const { result, data, message } = await this.axios.post('/project/contact-us', formData);
      console.log(message);
    } catch (e) {
      this.validationMessages(e);
    }
  }

  private cancel () {
    this.files = [];
    this.content = '';
    this.title = '';
  }
}
