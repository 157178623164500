
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { InvalidMessages } from "@/mixins/validation-mixin";


@Component
export default class ProjectSurveyTitle extends Vue {
  @Prop() invalidMessages!: Array<InvalidMessages>;
  @Prop() invalidMessage!: (field: string) => string | null;
  @Prop() validation!: (field: string) => boolean | null;
  @Prop() supLoading!: boolean;
  @PropSync('title') titleSync!: string;

}
